var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h3", { staticClass: "title" }, [_vm._v(_vm._s(_vm.content.title))]),
    _vm._v(" "),
    _c("div", { ref: "dom", staticClass: "container" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }