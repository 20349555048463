import { render, staticRenderFns } from "./GlobalService.vue?vue&type=template&id=6d11f2fc&scoped=true&"
import script from "./GlobalService.vue?vue&type=script&lang=js&"
export * from "./GlobalService.vue?vue&type=script&lang=js&"
import style0 from "./GlobalService.vue?vue&type=style&index=0&id=6d11f2fc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d11f2fc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/config/docker/alpine-build/web-fake/new/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6d11f2fc')) {
      api.createRecord('6d11f2fc', component.options)
    } else {
      api.reload('6d11f2fc', component.options)
    }
    module.hot.accept("./GlobalService.vue?vue&type=template&id=6d11f2fc&scoped=true&", function () {
      api.rerender('6d11f2fc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/home/global_service/GlobalService.vue"
export default component.exports