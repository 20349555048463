<script>
import * as echarts from 'echarts';
import { onMounted, ref } from '@vue/composition-api';
import { useLang, useContent } from '@/utils/hooks';
import lang from './GlobalService.json';
import city from './city.json';

export default {
  name: 'GlobalService',
  setup() {
    const content = useContent(lang);
    const langText = useLang();
    const dom = ref(null);

    onMounted(async () => {
      const world = (await import('./world.json')).default;
      const chart = echarts.init(dom.value);
      echarts.registerMap('WORLD', world);
      const option = {
        geo: {
          show: true,
          roam: false,
          map: 'WORLD',
          emphasis: {
            disabled: true,
          },
          zoom: 1.1,
          regions: world.features.map(item => ({
            name: item.properties.name,
            itemStyle: {
              areaColor: '#F0FFF0',
            },
          })),
        },
        series: [{
          type: 'effectScatter',
          coordinateSystem: 'geo',
          mapType: 'world',
          data: city,
          symbolSize: 8,
          rippleEffect: {
            brushType: 'stroke',
          },
          hoverAnimation: true,
          emphasis: {
            label: {
              show: true,
              formatter: params => (langText.value === 'en' ? params.data.en : params.data.zh_cn),
              position: 'right',
              color: '#000',
            },
            focus: 'series',
            blurScope: 'coordinateSystem',
          },
        }],
      };
      chart.setOption(option);
    });

    return {
      content,
      dom,
    };
  },
};
</script>

<template>
  <div>
    <h3 class="title">{{content.title}}</h3>
    <div ref="dom" class="container"></div>
  </div>
</template>

<style lang="scss" scoped>
.title {
  margin: 32px 0;
  font-size: 24px;
  font-weight: normal;
  color: #1d274e;
  text-align: center;
}

.container {
  overflow: hidden;
  width: 100%;
  min-height: 400px;
}

@media screen and (min-width: 768px) {
  .title {
    margin: 40px 0;
    font-size: 32px;
  }

  .container {
    min-height: 800px;
  }
}
</style>
